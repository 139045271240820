import { Link } from "react-router-dom";
import SectionTitle from "./SectionTitle";

const life_journey = [
  {
    title: "Amato Lusitano High School",
    description: "Information Systems Management and Programming",
    date: "2015-2018",
    link: "https://www.esal.pt/",
    featured: 0,
    currently: 0
  },
  {
    title: "Polytechnic Institute of Castelo Branco",
    description: "Higher Technical Professional Course in Information Systems Technology and Programming",
    date: "2018 - 2020",
    link: "https://www.ipcb.pt/",
    featured: 0,
    currently: 0
  },
  {
    title: "WebTejo - Web Solutions",
    description: "CTO / Administrative Management",
    date: "2019",
    link: "https://webtejo.pt/",
    featured: 1,
    currently: 1
  },
  {
    title: "Polytechnic Institute of Castelo Branco",
    description: "Bachelor's Degree in Informatics and Multimedia",
    date: "2020",
    link: "https://www.ipcb.pt/",
    featured: 0,
    currently: 1
  },
];

export default function MyJourney(){
  return (
    <div className={"bg-gradient-to-br sm:px-0 lg:px-10 from-slate-800 to-slate-700 text-white"}>
      <div className={"container mx-auto px-10 py-10"}>
        <section>
          <SectionTitle title={"My Journey"} />
        </section>
        <section className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-2">
          {life_journey.map((stage, index) => (
            <Link to={stage.link} key={index} className={"rounded-lg p-4 hover:shadow-lg border-slate-500 hover:border-slate-400 border transition-all " + (stage.featured ? "border-slate-100 border-2 bg-gradient-to-br from-slate-600 to-slate-400" : "bg-slate-600")} target="_blank">
              <div>
                <h3 className={"text-lg font-bold"}>{stage.title}</h3>
                <p className={"text-md"}>{stage.description}</p>
                <p className={"text-md"}>{stage.date}{(stage.currently === 1 && ' - currently')}</p>
              </div>
            </Link>
          ))}
        </section>
      </div>
    </div>
  );
}
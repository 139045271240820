interface SectionTitle_Props {
  title: string;
  underlined?: boolean;
  type?: "center" | "left" | "right";
}

function SectionTitle(props: SectionTitle_Props) {
  const type = props.type || "left";
  return (
    <div className={"flex justify-" + type}>
      <h1 className={"text-3xl font-bold mb-9"}>{props.title}
      {(props.underlined || props.underlined == null) && type !== 'center' ? <span className={"underline underline-offset-4 relative top-3 right-40"}>&nbsp;&nbsp;&nbsp;</span> : <></>}</h1>
    </div>
  );
}

export default SectionTitle;
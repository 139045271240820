import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faGithub, faInstagram, faLinkedinIn, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faPhone, faEnvelope, faGlobe} from "@fortawesome/free-solid-svg-icons";
import SocialNetwork from "../Components/SocialNetwork";
import MyJourney from "../Components/MyJourney";
import {Link} from "react-router-dom";
import SectionTitle from "../Components/SectionTitle";
import BlogPostListingCard from "../Components/Blog/BlogPostListingCard";

const blog_placeholder = require("../assets/images/blog-placeholder.png");

function IndexPage() {
  return (
    <div id={"content"} className={"my-10"}>
      <section className={"container mx-auto sm:px-0 lg:px-20 mb-9"}>
        <div id="main-head" className={"flex"}>
          <div>
            <img src={require("../assets/images/foto.jpg")} alt={"profile"} className={"rounded-full w-40"}/>
          </div>
          <div className={"ml-10 py-3"}>
            <h2 className={"text-3xl font-semibold"}>Hey!</h2>
            <h5 className={"text-xl"}>My name is João Rodrigues. Welcome to my personal website.</h5>
            <h6 className={"text-lg"}>This website is currently under development.</h6>
            <div id="social-networks" className="mt-5">
              <ul id="social-list" className="flex">
                <SocialNetwork key={"SocialNetwork__Facebook"} url={"https://fb.me/DarkDracoon"} icon={faFacebookF}/>
                <SocialNetwork key={"SocialNetwork__Instagram"} url={"https://instagram.com/DarkDracoon"}
                               icon={faInstagram}/>
                <SocialNetwork key={"SocialNetwork__Twitter"} url={"https://twitter.com/drakzofficial"}
                               icon={faTwitter}/>
                <SocialNetwork key={"SocialNetwork__GitHub"} url={"https://github.com/darkdracoon"} icon={faGithub}/>
                <SocialNetwork key={"SocialNetwork__LinkedIn"} url={"https://linkedin.com/in/joãorodrigues"}
                               icon={faLinkedinIn}/>
              </ul>
            </div>
          </div>
        </div>
        <div className={"columns-3 text-center border-t mt-5 pt-5"}>
          <div className={"column"}>
            <FontAwesomeIcon icon={faPhone}/>
            &nbsp; 
            <a className="text-dark" href="tel:+351924418620">+351 924 41 86 20</a>
          </div>
          <div className={"column"}>
            <FontAwesomeIcon icon={faEnvelope}/>
            &nbsp; 
            <a className="text-dark" href="mailto:eu@xn--joorodrigues-3bb.pt">eu@joãorodrigues.pt</a>
          </div>
          <div className={"column"}>
            <FontAwesomeIcon icon={faGlobe} />
            &nbsp;
            Castelo Branco, Portugal
          </div>
        </div>
      </section>
      <MyJourney></MyJourney>
      {/*
      <section className={"container mx-auto sm:px-0 lg:px-20 py-10"}>
        <SectionTitle title={"Recent Posts"} type={"center"} />
        <div className={"grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 lg:grid-cols-3"}>
          <BlogPostListingCard
            type="vertical"
            title={"The Coldest Sunset"}
            description={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil."}
            date="2020-01-01"
            categories={[{
              id: 1,
              name: "Categoria 1",
            },
            {
              id: 2,
              name: "Categoria 2",
            }]}
            link={"/blog"}
            image={blog_placeholder}
          />

          <BlogPostListingCard
            type="vertical"
            title={"The Coldest Sunset"}
            description={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil."}
            date="2020-01-01"
            categories={[{
              id: 1,
              name: "Categoria 1",
            },
            {
              id: 2,
              name: "Categoria 2",
            }]}
            link={"/blog"}
            image={blog_placeholder}
          />

          <BlogPostListingCard
            type="vertical"
            title={"The Coldest Sunset"}
            description={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil."}
            date="2020-01-01"
            categories={[{
              id: 1,
              name: "Categoria 1",
            },
            {
              id: 2,
              name: "Categoria 2",
            }]}
            link={"/blog"}
            image={blog_placeholder}
          />
        </div>
        <p className="text-center">No posts were found on the Blog.</p>
      </section>
      <section>
        <div className={"container mx-auto sm:px-0 lg:px-20 py-5"}>
          <div className={"bg-slate-500 px-10 py-5 rounded-2xl text-white flex items-center"}>
            <div className={"w-full"}>
              <h4 className={"text-xl font-bold"}>Do you want to talk with me?</h4>
              <h5 className={"text-md font-semibold"}>Check how you can do it by pressing the button!</h5>
            </div>
            <div className={"w-full text-right items-center"}>
              <Link to={"/contact"}
                    className={"bg-indigo-600 hover:bg-indigo-800 text-white text-lg leading-6 font-medium py-2 px-3 rounded-lg transition duration-100"}>Contacts</Link>
            </div>
          </div>
        </div>
      </section>*/}
    </div>
  );
}

export default IndexPage;
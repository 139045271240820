import React from 'react';
import './App.css';
import {Link, Route, Routes, useLocation} from "react-router-dom";
import IndexPage from "./Pages/IndexPage";
import BlogPage from "./Pages/BlogPage";
import PortfolioPage from "./Pages/PortfolioPage";
import GalleryPage from "./Pages/GalleryPage";
import ContactPage from "./Pages/ContactPage";
import SocialNetwork from "./Components/SocialNetwork";
import {faFacebookF, faGithub, faInstagram, faLinkedinIn, faTwitter, IconDefinition} from "@fortawesome/free-brands-svg-icons";
import TermsPage from './Pages/TermsPage';
import PrivacyPage from './Pages/PrivacyPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faContactCard, faNewspaper, faPhotoFilm, faUser } from '@fortawesome/free-solid-svg-icons';
import BlogPostPage from './Pages/BlogPostPage';

type NavItemTypes = {
  title: string;
  url: string;
  icon: IconDefinition;
  enabled: boolean;
}
function App() {
  const { pathname } = useLocation();

  const navItems: NavItemTypes[] = [
    {title: "Início", url: "/", icon: faUser, enabled: true},
    {title: "Blog", url: "/blog", icon: faNewspaper, enabled: false},
    {title: "Portfólio", url: "/portfolio", icon: faGithub, enabled: false},
    {title: "Galeria", url: "/gallery", icon: faPhotoFilm, enabled: false},
    {title: "Contacto", url: "/contact", icon: faContactCard, enabled: false},
  ];

  return (
    <main className={"dark:text-white dark:bg-neutral-900 bg-neutral-50 pb-10"}>
      <header className="App-header">
        <div className={"header-img text-center py-10 pb-14 bg-slate-100"}>
          <h1 className={"text-4xl font-bold"}>João Rodrigues<span
            className={"font-normal relative top-5 right-7 text-xl"}>with tilde!</span></h1>
        </div>
        {/*<nav className="flex sm:justify-center space-x-4 py-2 shadow-neutral-100 dark:shadow-neutral-900 dark:bg-neutral-800 shadow-lg">
          {navItems.map((val, i) => {
            if (val.enabled) {
            return (<Link to={val.url} key={"Link"+val.title} className={(pathname === val.url ? "dark:bg-slate-600 dark:text-slate-100 bg-slate-100 text-slate-900 border-b-2 " : "") + "rounded-lg px-3 py-2 text-slate-700 dark:text-slate-100 font-medium hover:bg-slate-100 hover:text-slate-900 transition duration-500"}>
              <FontAwesomeIcon icon={val.icon}></FontAwesomeIcon>
            </Link>)
            }
          }
          )}
        </nav>*/}
      </header>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        { /* <Route path="/blog" element={<BlogPage />} /> */ }
        { /*<Route path="/blog/:id" element={<BlogPostPage />} /> 
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/gallery" element={<GalleryPage images={[
          {
            id: 1,
            alt: "Ice cream",
            url: "https://images.unsplash.com/photo-1497034825429-c343d7c6a68f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8",
            width: 3456,
            height: 5184,
          },
          {
            id: 2,
            alt: "Dog in the snow",
            url: "https://images.unsplash.com/photo-1675354053722-637258306868?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8ZG9nJTIwaW4lMjB0aGUlMjBzbm93fGVufDB8fDB8fA",
            width: 3576,
            height: 2592,
          },
          
          {
            id: 3,
            alt: "Eclipse",
            url: "https://images.unsplash.com/photo-1504191237171-15295dcf8b69?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8OXwyMDU4NDgxfHxlbnwwfHx8fA",
            width: 2246,
            height: 1497,
          },
          {
            id: 4,
            alt: "Roads out of focus",
            url: "https://images.unsplash.com/photo-1463974680696-268d93d62939?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format",
            width: 4288,
            height: 2848,
          },
          {
            id: 5,
            alt: "Roads out of focus",
            url: "https://images.unsplash.com/photo-1463974680696-268d93d62939?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format",
            width: 4288,
            height: 2848,
          },
          {
            id: 6,
            alt: "Ice cream",
            url: "https://images.unsplash.com/photo-1497034825429-c343d7c6a68f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8",
            width: 3456,
            height: 5184,
          },

          {
            id: 7,
            alt: "Dog in the snow",
            url: "https://images.unsplash.com/photo-1675354053722-637258306868?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8ZG9nJTIwaW4lMjB0aGUlMjBzbm93fGVufDB8fDB8fA",
            width: 3576,
            height: 2592,
          },
          
          {
            id: 8,
            alt: "Eclipse",
            url: "https://images.unsplash.com/photo-1504191237171-15295dcf8b69?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8OXwyMDU4NDgxfHxlbnwwfHx8fA",
            width: 2246,
            height: 1497,
          },
          {
            id: 9,
            alt: "Roads out of focus",
            url: "https://images.unsplash.com/photo-1463974680696-268d93d62939?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format",
            width: 4288,
            height: 2848,
          },
          {
            id: 10,
            alt: "Roads out of focus",
            url: "https://images.unsplash.com/photo-1463974680696-268d93d62939?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format",
            width: 4288,
            height: 2848,
          },
        ]} />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />*/ }
        <Route
          path="*"
          element={
            <div id={"content"} className={"my-14 text-center"}>
              <h1 className={"font-bold text-4xl"}>Page not found</h1>
              <h2 className={"font-semibold mt-2 mb-5"}>The page you were trying to browse does not exist.</h2>
              <span id={"errorCode"} className={"font-light text-2xl"}>404</span>
            </div>
          }
        />
      </Routes>
      <hr className={"my-5"} />
      <footer className={"flex flex-col justify-center items-center"}>
        { pathname !== '/' &&
          (
        <div id="social-networks" className="mt-5 mb-5">
          <ul id="social-list" className="flex">
            <SocialNetwork key={"SocialNetwork__Facebook"} url={"https://fb.me/DarkDracoon"} icon={faFacebookF}/>
            <SocialNetwork key={"SocialNetwork__Instagram"} url={"https://instagram.com/DarkDracoon"}
                           icon={faInstagram}/>
            <SocialNetwork key={"SocialNetwork__Twitter"} url={"https://twitter.com/drakzofficial"}
                           icon={faTwitter}/>
            <SocialNetwork key={"SocialNetwork__GitHub"} url={"https://github.com/darkdracoon"} icon={faGithub}/>
            <SocialNetwork key={"SocialNetwork__LinkedIn"} url={"https://linkedin.com/in/joãorodrigues"}
                           icon={faLinkedinIn}/>
          </ul>
        </div>
          )
        }
        
        <p className={"copyright"}>Copyright &copy; 2023 - João Rodrigues</p>
        {/*<p><Link to={"/privacy"} className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"}>Política de Privacidade</Link> - <Link to={"/terms"} className={"underline underline-offset-4 transition-all motion-reduce:transition-none hover:animate-pulse hover:underline-offset-2"}>Termos e Condições</Link></p>*/}
      </footer>
    </main>
  );
}

export default App;
import {
  IconDefinition
} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface SocialNetwork_Link {
  url: string;
  icon: IconDefinition;
}

function SocialNetwork(props: SocialNetwork_Link) {
  return <a href={props.url} target="_blank" rel="noreferrer"
            className={"mx-0.5 hover:shadow-[0_0_5px_0_rgba(0,0,0,0.75)] rounded-full flex items-center justify-center " +
              "bg-neutral-800 hover:bg-white transition duration-300 text-white hover:text-black h-10 w-10"}>
      <FontAwesomeIcon icon={props.icon} className={"h-5 w-5"}/>
  </a>;
}

export default SocialNetwork;